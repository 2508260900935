<template>
    <div class="td-banner info border no-icon">
        <div class="td-flex">
            <h4 class="ma-0 text-normal"><strong>{{location.name}} ({{location.address.city}}, {{location.address.regionCode}})</strong></h4><br />
            <button class="td-btn secondary-outlined" @click="goBack">Back to Location Admin</button>
        </div>
        <div class="td-flex my-3">
            <div>
                <p><strong>Status:</strong> {{getStatus}}</p>
                <p><strong>Rating Value:</strong> {{getRatingValue}}</p>
                <p><strong>Last Verified:</strong> {{getLastVerifiedDate}}</p>
                <p><strong>Last Modified:</strong> {{getLastModifiedDate}}</p>
            </div>
            <div>
                <p><a :href="viewLink">View</a></p>
                <p><a :href="emailLink">Email</a></p>
                <p><a :href="analyticsLink">Analytics</a></p>
                <p><a :href="newTaskLink">New Task</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import script from './LocationSummary';
    export default script;
</script>

<style lang="less">
    @import "./LocationSummary.less";
</style>