import { LocationModel, LocationStatus } from "@truckdown/locations";
import { defineComponent, ref, Ref, computed } from "vue";

interface IData {
    location: Ref<LocationModel>
};

const getData = function (props: any) {
    return {
        location: ref<LocationModel>(props.location)
    } as IData;
}

const getMethods = function (props: any, data: IData) {
    return {
        dateDisplay: function (dateUTC: Date | string): Date | string | undefined {
            if (dateUTC)
                return new Date(dateUTC).toLocaleString();
            else
                return undefined;
        },
        goBack: function (): void {
            window.history.back();
        }
    }
}

const getComputed = function (data: IData) {
    return {
        getStatus: computed(() => {
            if (data.location?.value?.status) {
                return LocationStatus[data.location.value.status];
            }
        }),
        getRatingValue: computed(() => {
            if (data.location.value?.properties?.ratingValue?.value) {
                return data.location.value.properties.ratingValue.value < 0 ?
                    0 : data.location.value.properties.ratingValue.value
            }
        }),
        getLastVerifiedDate: computed(() => {
            if (new Date(data.location.value.properties.lastVerifiedDate).getFullYear() == 0) {
                return "Location not verified"
            } else {
                return new Date(data.location.value.properties.lastVerifiedDate).toLocaleString();
            }
        }),
        getLastModifiedDate: computed(() => {
            if (new Date(data.location.value.properties.modifiedTime).getFullYear() == 0) {
                return "Location not modified"
            } else {
                return new Date(data.location.value.properties.modifiedTime).toLocaleString();
            }
        }),
        viewLink: computed(() => {
            return '/location-admin/view-location/' + encodeURIComponent(data.location.value.id) + '?returnUrl=' + encodeURIComponent(window.location.href);
        }),
        emailLink: computed(() => {
            return '/location-admin/view-location/' + encodeURIComponent(data.location.value.id) + '?State=emails&returnUrl=' + encodeURIComponent(window.location.href);
        }),
        analyticsLink: computed(() => {
            return '/analytics/admin/l/' + encodeURIComponent(data.location.value.id) + '?returnUrl=' + encodeURIComponent(window.location.href);
        }),
        newTaskLink: computed(() => {
            return '/location-admin/view-location/' + encodeURIComponent(data.location.value.id) + '?State=tasks&returnUrl=' + encodeURIComponent(window.location.href);
        })
    }
}

export default defineComponent({
    name: "LocationSummary",
    props: {
        location: {
            type: Object as () => LocationModel,
            required: true
        }
    },
    setup: function (props, ctx) {
        const data = getData(props);
        const computed = getComputed(data);
        const methods = getMethods(props, data);

        return {
            ...data,
            ...methods,
            ...computed
        };
    }
});