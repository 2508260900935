import { BannerModel, BannerStatus } from "@truckdown/locations";
import { defineComponent, ref, Ref, computed } from "vue";

interface IData {
    banner: Ref<BannerModel>
};

const getData = function (props: any) {
    return {
        banner: ref<BannerModel>(props.banner)
    } as IData;
}

const getMethods = function (props: any, data: IData) {
    return {
        dateDisplay: function (dateUTC: Date | string): Date | string | undefined {
            if (dateUTC)
                return new Date(dateUTC).toLocaleString();
            else
                return undefined;
        },
        goBack: function (): void {
            window.history.back();
        }
    }
}

const getComputed = function (data: IData) {
    return {
        getStatus: computed(() => {
            if (data.banner?.value.status) {
                return BannerStatus[data.banner.value.status];
            }
        }),
        getDisplayArea: computed(() => {
            if (data.banner?.value.regionCode) {
                return data.banner.value.regionCode + ", " + data.banner.value.countryCode;
            }

            return data.banner.value.countryCode;
        }),
        getServices: computed(() => {
            if (data.banner?.value.bannerServices) {
                return data.banner.value.bannerServices.toString();
            }

            return "";
        }),
        imageLink: computed(() => {
            return '/file/banner/' + data.banner.value.imageId;
        }),
        websiteLink: computed(() => {
            if (data.banner.value.websiteAddress.toLowerCase().startsWith("http")) {
                return data.banner.value.websiteAddress;
            }
            return "https://" + data.banner.value.websiteAddress;
        }),
        viewLink: computed(() => {
            return '/banners-admin/view-banner/' + encodeURIComponent(data.banner.value.id) + '?returnUrl=' + encodeURIComponent(window.location.href);
        }),
        analyticsLink: computed(() => {
            return '/analytics/admin/ba/' + encodeURIComponent(data.banner.value.id) + '?returnUrl=' + encodeURIComponent(window.location.href);
        })
    }
}

export default defineComponent({
    name: "BannerSummary",
    props: {
        banner: {
            type: Object as () => BannerModel,
            required: true
        }
    },
    setup: function (props, ctx) {
        const data = getData(props);
        const computed = getComputed(data);
        const methods = getMethods(props, data);

        return {
            ...data,
            ...methods,
            ...computed
        };
    }
});