<template>
    <div class="td-banner info border no-icon">
        <div class="td-flex">
            <img :src="imageLink" />
            <button class="td-btn secondary-outlined" @click="goBack">Back to Banner Admin</button>
        </div>
        <div class="td-flex my-3">
            <div>
                <p><strong>Status:</strong> {{getStatus}}</p>
                <p><strong>Website Address:</strong> <a :href="websiteLink" target="_blank">{{banner.websiteAddress}}</a> </p>
                <p><strong>Display Area:</strong> {{getDisplayArea}}</p>
                <p><strong>Services:</strong> {{getServices}}</p>
            </div>
            <div>
                <p><a :href="viewLink">View</a></p>
                <p><a :href="analyticsLink">Analytics</a></p>
            </div>
        </div>
    </div>
</template>

<script>
    import script from './BannerSummary';
    export default script;
</script>

<style lang="less">
    @import "./BannerSummary.less";
</style>